import Vue from 'vue'
import Vuex from 'vuex'
import menu_list from './menu_buf.js'

Vue.use(Vuex)

const store = new Vuex.Store({
	state: {

		//初始化
		init_status:1,//初始化状态(1:未初始化,2:初始化中,3:已初始化成功,4:初始化失败)

		//参数
		cos_bucket_list:{},

		//用户相关
		is_login:2,//是否已登录(1:已登录,2:未登录)
		app_user_info:{},//用户信息
		url_btns:{},//页面地址对应页内权限按钮

		//客户相关
		customer_info:{},
		
		//当前页面地址
		now_url:'',

		//默认页面
		def_page:'',

		//基础数据
		basic_data:{},

		//语言
		language_list:[],
		language_obj:{},
		dictionary_list:[],
		dictionary_obj:{},
		language_choosed:'',
	},
	mutations: {
		end_of_init(state,data){//初始化之后
			if(data.init_status){
				state.init_status=data.init_status
			}
			if(data.cos_bucket_list){
				state.cos_bucket_list=data.cos_bucket_list
			}
			if(data.basic_data){
				state.basic_data=data.basic_data
			}

			//语言处理
			let language_obj={}
			let dictionary_obj={};
			let language_def=''
			if(data.language_list){
				for(let item of data.language_list){
					if(item.is_def==1){
						language_def=item.id
					}
					language_obj[item.id]=item.name;
					dictionary_obj[item.id]={};
				}
				state.language_list=data.language_list
			}
			if(data.dictionary_list){
				for(let item of data.dictionary_list){
					if(!dictionary_obj[item.language_id])dictionary_obj[item.language_id]={}
					dictionary_obj[item.language_id][item.field_name]=item.field_show
				}
				state.dictionary_list=data.dictionary_list
			}
			state.language_obj=language_obj
			state.dictionary_obj=dictionary_obj
			
			let language_choosed=localStorage.getItem('language_choosed');
			let language_final=''
			if(language_obj[language_choosed]){
				language_final=language_choosed
			}else language_final=language_def
			state.language_choosed=language_final
		},
		end_of_login(state,data){//登陆成功之后
			if(data.is_login){
				state.is_login=data.is_login
			}
			if(data.app_user_info){
				data.app_user_info.menu_list=menu_list
				state.app_user_info=data.app_user_info
			}

			//如果已登录则处理菜单
			if(data.is_login==1){

				//菜单处理
				let menu_list=data.app_user_info.menu_list
				let url_btns={};
				for(var item of menu_list){

					//页面地址对应页内权限按钮
					let item_url=item.url.trim()
					if(item_url){
						let item_controlled_btns=item.controlled_btns.split(',')
						for(var btn_index in item_controlled_btns){
							if(!item_controlled_btns[btn_index].trim()){
								item_controlled_btns.splice(btn_index,1)
							}
						}
						url_btns[item_url]=item_controlled_btns
					}
				}

				state.url_btns=url_btns
			}
		},
		end_of_logout(state){//登出之后
			state.is_login=2
			state.app_user_info={}
		},
		save_user_info(state,data){//保存用户数据
			state.app_user_info=data
		},
		save_now_url(state,data){//保存当前页面地址
			state.now_url=data
		},
		set_customer_info(state,obj){//保存客户信息
			state.customer_info=obj
		},
	},
	actions: {
		
	}
})

export default store
